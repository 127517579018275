<template>
  <ExtendedView :tabs="tabs">
    <template slot="list">
      <ActionList
        :empty-text="$t('noUsersFound')"
        :load-items-callback="getUsers"
      >
        <template v-slot:item="{ item, index }">
          <UserListItem
            v-if="item !== null"
            :key="item.uid"
            :user="item"
            @selectUser="selectUser"
          />
          <v-divider
            inset
            :key="index"
          />
        </template>
      </ActionList>
    </template>
    <template slot="profile">
      <UserProfile :user="selectedUser" />
    </template>
  </ExtendedView>
</template>

<script>
  import UserListItem from "@/components/UserListItem"
  import ExtendedView from "@/components/common/ExtendedView"
  import ActionList from "@/components/common/list/ActionList"
  import UserProfile from "@/components/user/UserProfile"

  export default {
    name: 'Users',
    components: {
      UserListItem,
      ExtendedView,
      ActionList,
      UserProfile
    },
    data: () => ({
      showInviteDialog: false,
      users: null,
      selectedUser: null,
    }),
    methods: {
      selectUser(user) {
        this.selectedUser = user
      },
      getUsers() {
        return this.$store.dispatch('getUsers').then(users => {
          this.users = users
          return users
        })
      }
    },
    computed: {
      query: function() {
        return this.$store.state.searchQuery
      },
      blockedUsers() {

        if(this.users === null || this.users === undefined) {
          return null
        }

        return this.users.filter(user => {
          return !user.active
        })
      },
      activeUsers() {

        if(this.users === null || this.users === undefined) {
          return null
        }

        return this.users.filter(user => {
          return user.active && !user.isDevice
        })
      },
      deviceUsers() {

        if(this.users === null || this.users === undefined) {
          return null
        }

        return this.users.filter(user => {
          return user.isDevice
        })
      },
      activeUsersCount() {

        if(this.activeUsers === null) {
          return 0
        }

        return this.activeUsers.length
      },
      blockedUsersCount() {

        if(this.blockedUsers === null) {
          return 0
        }

        return this.blockedUsers.length
      },
      deviceUsersCount() {

        if(this.deviceUsers === null) {
          return 0
        }

        return this.deviceUsers.length
      },
      tabs: function() {
        return [
          {
            title: this.$t('active'),
            key: 'active',
            badge: {
              color: 'green',
              content: this.activeUsersCount
            }
          },
          {
            title: this.$t('blocked'),
            key: 'blocked',
            badge: {
              color: 'red',
              content: this.blockedUsersCount
            }
          },
          {
            title: this.$t('device'),
            key: 'device',
            badge: {
              color: 'green',
              content: this.deviceUsersCount
            }
          }
        ]
      },
      selectedAppBarTab: function() {
        return this.$store.state.selectedAppBarTab
      },
    },
    watch: {
      users() {
        this.$store.commit('updateAppBarTabs', this.tabs)
      }
    },
    created() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
      this.getUsers()
    }
  }
</script>
