<template>
  <v-list-item
    two-line
    link
    @click="$emit('selectUser', user)"
  >
    <v-list-item-avatar>
      <v-avatar color="primary">
        <span class="white--text title" />
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ user.displayName }}</v-list-item-title>
      <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            :href="'mailto:' + user.email"
          >
            <v-icon color="green lighten-1">
              mdi-email
            </v-icon>
          </v-btn>
        </template>
        <span>{{ user.email }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            :href="'tel:' + user.phone"
          >
            <v-icon color="green lighten-1">
              mdi-phone
            </v-icon>
          </v-btn>
        </template>
        <span>{{ user.phone }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action>
      <v-btn icon>
        <v-icon color="grey lighten-1">
          mdi-information
        </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  export default {
    name: 'UserListItem',
    props: {
      user: {
        type: Object, 
        default: () => {}
      }
    },
    data: () => ({

    }),
    computed: {
    }
  }
</script>
